<template>
  <div>
    <div class="d-flex justify-content-between">
      <div class="d-flex">
        <!-- avatar -->
        <span class="avatar-title rounded-circle avatar-sm" v-if="!review.author.photo">
          {{ review.author.name | acronym }}
        </span>
        <img
          alt="authImg"
          class="avatar-img rounded-circle avatar-sm"
          :src="review.author.photo"
          v-else
        />

        <!-- customerName/rating -->
        <div class="ml-3">
          <h4 class="card-title mb-2">{{ review.author.name }}</h4>
          <div class="stars float-left mr-2" style="height: 22.5px">
            <StarRating :rating="review.rating" v-if="network !== 'facebook'" />
          </div>
        </div>
      </div>
      <span>
        <img
          :src="'/theme/img/platforms/' + network + '.svg'"
          alt="..."
          class="avatar-img avatar-xs rounded"
        />
      </span>
    </div>

    <!-- text -->
    <div class="mb-6 mt-2">
      <p class="review-text" v-if="review.text && review.text.length">
        <read-more
          more-str="read more"
          :text="review.text"
          link="#"
          less-str=" show less"
          :max-chars="300"
        ></read-more>
      </p>
      <p class="text-muted mb-3 review-text" v-else>
        <i>Author did not leave a comment.</i>
      </p>
    </div>

    <!-- footer -->
    <div style="position: absolute; bottom: 24px; width: inherit">
      <div class="d-flex justify-content-between text-muted">
        <span>
          <span class="badge badge-light mr-2"> {{ this.locationName }}</span>
          <span class="fe fe-clock"></span>&nbsp;
          <time v-if="review.timestamp">
            {{ this.$moment(review.timestamp).format('ll') }}
          </time>
        </span>
      </div>
    </div>
    <div
      style="position: absolute; bottom: 24px; right: 24px"
      v-if="!selectIsClickoutBlacklisted(this.network)"
    >
      <div class="d-flex justify-content-between text-muted">
        <button class="btn btn-link p-1 text-muted" @click="handleClickOut">
          <ExternalLinkIcon size="1.2x"></ExternalLinkIcon>
        </button>
      </div>
    </div>

    <GoogleProfileIdModal
      ref="googleIdModal"
      @save="openManually"
      :location="reviewLocation"
      :reviewSubscription="reviewSubscription"
      v-if="network === 'google' && !hasGoogleBusinessProfileId"
    />
  </div>
</template>

<script>
import { ExternalLinkIcon } from 'vue-feather-icons'
import StarRating from '@/components/Modules/Review/StarRating'
import GoogleProfileIdModal from '@/components/Modals/GoogleProfileIdModal'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')
const ReviewModule = createNamespacedHelpers('review')

export default {
  name: 'ReviewCard',
  props: {
    review: {
      type: Object,
      required: true,
    },
  },
  components: {
    StarRating,
    ExternalLinkIcon,
    GoogleProfileIdModal,
  },
  filters: {
    acronym(value) {
      if (!value) return ''
      return value.match(/\b\w/g).join('').toUpperCase().substring(0, 2)
    },
  },
  computed: {
    ...LocationModule.mapGetters(['selectLocationName', 'selectActiveLocations']),
    ...ReviewModule.mapGetters(['selectIsClickoutBlacklisted']),
    reviewLocation() {
      const location = this.selectActiveLocations.find((l) => {
        if (l.subscriptions && this.reviewSubscription) {
          return l.subscriptions.find(
            (sub) => sub.reviewSubscription._id === this.reviewSubscription._id
          )
            ? true
            : false
        }
        return false
      })
      return location
    },
    locationName() {
      if (this.reviewLocation) {
        return this.selectLocationName(this.reviewLocation._id)
      }
      return ''
    },
    reviewSubscription() {
      const reviewSubscriptionMap = this.selectActiveLocations.reduce((data, l) => {
        if (l.subscriptions) {
          l.subscriptions.forEach((s) => {
            if (s.reviewSubscription) {
              data[s.reviewSubscription._id] = s.reviewSubscription
            }
          })
        }
        return data
      }, {})

      return reviewSubscriptionMap[this.review.reviewSubscription]
    },
    hasGoogleBusinessProfileId() {
      return this.reviewSubscription?.googleBusinessProfileId
    },
    network() {
      return this.reviewSubscription?.network
    },
  },
  methods: {
    ...LocationModule.mapActions(['addGoogleBusinessProfileId']),
    ...ReviewModule.mapActions(['refreshList']),
    handleClickOut() {
      if (['yelp', 'facebook'].includes(this.network)) {
        window.open(this.review.replyUrl, '_blank')
      } else if (this.network === 'google') {
        if (this.hasGoogleBusinessProfileId) {
          const replyUrl = this.createGoogleReplyUrl(
            this.reviewSubscription?.googleBusinessProfileId,
            this.review.id
          )
          window.open(replyUrl, '_blank')
        } else {
          this.$refs.googleIdModal.open()
        }
      } else if (this.network === 'tripadvisor') {
        const oldReplyUrl = this.review.replyUrl
        const regex1 = /ShowUserReviews/
        const regex2 = /-r/
        let newReplyUrl = oldReplyUrl.replace(regex1, 'ReputationManager')
        newReplyUrl = newReplyUrl.replace(regex2, '-a_reviewId.')
        window.open(newReplyUrl, '_blank')
      }
    },
    openManually(profileId) {
      this.addGoogleBusinessProfileId({
        locationId: this.reviewLocation._id,
        reviewSubscription: this.review.reviewSubscription,
        googleBusinessProfileId: profileId,
      })
      const replyUrl = this.createGoogleReplyUrl(profileId, this.review.id)
      window.open(replyUrl, '_blank')
      this.refreshList()
    },
    createGoogleReplyUrl(profileId, googleReviewId) {
      return `https://business.google.com/n/${profileId}/reviews/${googleReviewId}`
    },
  },
}
</script>

<style lang="scss">
.review-text p {
  display: inline;
}

.badge {
  font-size: 13px;
  margin-bottom: 2px;
}
</style>
